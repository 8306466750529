import { SidebarContainer } from 'components/UI/Layout/Containers/Sidebar/SidebarContainer/SidebarContainer';
import { TextLink } from 'components/UI/Link/TextLink';

import styles from './PodcastPlayer.module.scss';

interface PodcastPlayerProps
  extends React.AllHTMLAttributes<HTMLIFrameElement> {
  allHref?: string;
}
interface IPodcastPlayerProps {
  data: PodcastPlayerProps;
}

export const PodcastPlayer = ({ data }: IPodcastPlayerProps) => {
  const {
    allHref,
    title = 'team-podcast-player',
    height = '180',
    width = '100%',
    src,
    ...iframeProps
  } = data;

  return (
    <SidebarContainer title="Podcasts">
      <div className={styles.iframeContainer}>
        <iframe
          className={styles.iframeItem}
          frameBorder="no"
          height={height}
          scrolling="no"
          seamless
          src={src}
          title={title}
          width={width}
          {...iframeProps}
        />
        {data.allHref && (
          <TextLink
            className={styles.tabFont}
            color="primary"
            href={allHref}
            rel="noopener"
            target="_blank"
            underline="none"
            variant="subtitle1"
          >
            All Episodes
          </TextLink>
        )}
      </div>
    </SidebarContainer>
  );
};
