import { useSite } from '@on3/ui-lib/index';
import clsx from 'clsx';
import { SiteLogo } from 'components/Image/SiteLogo';

import styles from './SEOHomeText.module.scss';

interface SEOHomeTextProps {
  className?: string;
  children?: React.ReactNode;
}

export const SEOHomeText = ({ className, children }: SEOHomeTextProps) => {
  const { currentSite } = useSite();

  if (children) {
    return (
      <section className={clsx(styles.block, className)}>
        <div className={styles.inner}>{children}</div>
      </section>
    );
  }

  if (!currentSite?.tagLine) {
    return null;
  }

  return (
    <section className={clsx(styles.block, className)}>
      <div className={styles.inner}>
        <SiteLogo className={styles.metaIcon} slug="-logo-full" width={42} />
        <h1>{currentSite?.tagLine}</h1>
      </div>
    </section>
  );
};
