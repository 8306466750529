import type { SVGProps } from 'react';

export const Instagram = ({ className, fill }: SVGProps<SVGSVGElement>) => {
  return (
    <svg
      className={className}
      height="19"
      viewBox="0 0 19 19"
      width="19"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M18.9697 13.4828C18.9697 15.9868 16.9331 18.0234 14.429 18.0234H5.5104C3.00669 18.0234 0.969727 15.9868 0.969727 13.4828V4.56411C0.969727 2.0604 3.00669 0.0234375 5.5104 0.0234375H14.429C16.9331 0.0234375 18.9697 2.0604 18.9697 4.56411V13.4828ZM14.429 1.98248H5.51037C4.08682 1.98248 2.92865 3.14065 2.92865 4.56419V13.4828C2.92865 14.9064 4.08682 16.0649 5.51037 16.0649H14.429C15.8526 16.0649 17.0111 14.9067 17.0111 13.4828V4.56419C17.0111 3.14065 15.8529 1.98248 14.429 1.98248ZM9.96969 13.6595C7.41365 13.6595 5.33401 11.5799 5.33401 9.02352C5.33401 6.46747 7.41365 4.38783 9.96969 4.38783C12.5261 4.38783 14.6057 6.46747 14.6057 9.02352C14.6057 11.5796 12.5257 13.6595 9.96969 13.6595ZM14.7547 5.34631C14.1496 5.34631 13.6576 4.85424 13.6576 4.24948C13.6576 3.64472 14.1496 3.15265 14.7547 3.15265C15.3595 3.15265 15.8516 3.64472 15.8516 4.24948C15.8516 4.85424 15.3595 5.34631 14.7547 5.34631ZM7.29255 9.02288C7.29255 7.54732 8.49406 6.34648 9.96961 6.34648C11.4455 6.34648 12.6463 7.54766 12.6463 9.02288C12.6463 10.4991 11.4458 11.7003 9.96961 11.7003C8.49406 11.7003 7.29255 10.4991 7.29255 9.02288Z"
        fill={fill}
      />
    </svg>
  );
};
