import { useAuth } from '@on3/ui-lib/src/contexts/AuthProvider';

import styles from './PianoPromo.module.scss';

export const PianoPromo = ({ id }: { id?: string }) => {
  const { user } = useAuth();
  const pianoId = id || 'pianoPromo';

  if (user?.has || user?.rg?.includes('administrator')) {
    return null;
  }

  return <div className={styles.promo} id={pianoId} />;
};
