import { Facebook, YouTube } from '@mui/icons-material';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import { FanSiteAvatar } from 'components/Image/FanSiteAvatar';
import { Instagram } from 'components/Svg/Instagram';
import { Twitter } from 'components/Svg/Twitter';
import { SideModuleWrapper } from 'components/UI/Layout/Containers/Sidebar/SideModuleWrapper';
import { TextLink } from 'components/UI/Link/TextLink';
import { useMemo } from 'react';

import styles from './Connect.module.scss';

export const TeamConnect = () => {
  const { currentSite } = useSite();
  const siteBackground = useMemo(
    () => ({ backgroundColor: currentSite?.primaryColor || '#000000' }),
    [currentSite?.primaryColor],
  );
  const twitter = currentSite?.twitterHandle;
  const instagram = currentSite?.instagramProfile;
  const facebook = currentSite?.facebookProfile;
  const youtube = currentSite?.youtubeProfile;
  const noProfiles = !twitter && !instagram && !facebook && !youtube;

  if (noProfiles) {
    return null;
  }

  return (
    <SideModuleWrapper title={`${currentSite?.siteName} Connect`}>
      <div className={styles.social}>
        <span className={styles.sitelogo} style={siteBackground}>
          <FanSiteAvatar
            height={50}
            name={currentSite?.organization?.name || 'default'}
            width={50}
          />
        </span>
        <ul>
          {twitter && (
            <li className={styles.socialitem}>
              <Twitter />
              <TextLink
                className={styles.link}
                href={`https://twitter.com/${twitter}`}
                rel="noopener noreferrer"
                target="_blank"
                underline="none"
              >
                {twitter}
              </TextLink>
            </li>
          )}
          {instagram && (
            <li className={styles.socialitem}>
              <Instagram />
              <TextLink
                className={styles.link}
                href={`https://www.instagram.com/${instagram}/`}
                rel="noopener noreferrer"
                target="_blank"
                underline="none"
              >
                {instagram}
              </TextLink>
            </li>
          )}
          {facebook && (
            <li className={styles.socialitem}>
              <Facebook />
              <TextLink
                className={styles.link}
                href={`https://www.facebook.com/${facebook}`}
                rel="noopener noreferrer"
                target="_blank"
                underline="none"
              >
                {facebook}
              </TextLink>
            </li>
          )}
          {youtube && (
            <li className={styles.socialitem}>
              <YouTube />
              <TextLink
                className={styles.link}
                href={`https://www.youtube.com/@${youtube}`}
                rel="noopener noreferrer"
                target="_blank"
                underline="none"
              >
                {youtube}
              </TextLink>
            </li>
          )}
        </ul>
      </div>
    </SideModuleWrapper>
  );
};
