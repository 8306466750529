import type { HeadlineResponse } from '@on3/api';

export const removeDuplicateArticles = ({
  list,
  keys,
}: {
  /** List of articles that needs to be filterd */
  list: HeadlineResponse[] | null;
  /** Keys to the articles that should be removed. */
  keys?: (number | undefined)[];
}) => {
  if (!keys) {
    return list;
  }

  return list?.filter((article) => {
    return keys.indexOf(article?.key || 0) < 0;
  });
};
