import type { HeadlineResponsePagedData } from '@on3/api';

import { ArticleSmall } from '../ArticleSmall/ArticleSmall';
import styles from './Feed.module.scss';

export const FeedCarousel = ({ list }: HeadlineResponsePagedData) => {
  return (
    <div className={styles.carouselContainer}>
      {list?.map((article) => {
        if (!article?.key) {
          return null;
        }

        return <ArticleSmall {...article} key={article?.key} />;
      })}
    </div>
  );
};
