import type { HeadlineResponse } from '@on3/api';
import { ResizedImage } from 'components/Image/ResizedImage';
import { TextLink } from 'components/UI/Link/TextLink';
import { Text } from 'components/UI/Typography/Text';

import styles from './ArticleSmall.module.scss';

export const ArticleSmall = ({
  fullUrl,
  featuredImage,
  title,
  primaryCategory,
}: HeadlineResponse) => {
  return (
    <TextLink href={fullUrl} underline="none">
      <article className={styles.articleContainer}>
        {featuredImage && (
          <ResizedImage
            className={styles.articleImage}
            {...featuredImage}
            fit="cover"
            gravity="0.5x0.5"
            height={100}
            quality={90}
            width={100}
          />
        )}

        <div className={styles.articleItemContainer}>
          <Text className={styles.articleCategory} variant="h5">
            {primaryCategory?.name}
          </Text>
          <Text className={styles.articleTitle} color="primary" variant="h5">
            {title}
          </Text>
        </div>
      </article>
    </TextLink>
  );
};
